import { useContext, useState } from 'react';

import Quiz from 'components/quiz/Quiz';
import { Wizard } from 'components/wizard/Wizard';
import { QuizContext } from 'providers/quiz';
import { initialData } from 'utils/quiz';

import s from './App.module.scss';

const App = (): JSX.Element => {
    const [activeCode, setActiveCode] = useState<string>('');
    const [nextStep, setNextStep] = useState<string>('');
    const steps = {
        intro: ['Q1'],
        Q1: ['Q2', 'LBG', 'LLP', 'PLC'],
        Q2: ['Q3', 'NONRES', 'Q1'],
        Q3: ['Q4', 'Q2'],
        Q4: ['Q5', 'Q3'],
        Q5: ['Q6', 'Q4'],
        Q6: ['Q7', 'Q5'],
        Q7: ['LBS1', 'LBS2', 'LBS3', 'LBS4', 'LBS5', 'Q6'],
        LBG: [],
        LLP: [],
        PLC: [],
        NONRES: [],
        LBS1: [],
        LBS2: [],
        LBS3: [],
        LBS4: [],
        LBS5: [],
        reset: ['Q1'],
    } as const;

    return (
        <QuizContext.Provider value={{ activeCode, setActiveCode, nextStep, setNextStep }}>
            <div className={s.app}>
                <div className={s.app__wrapper}>
                    <div className={s.app__form}>
                        <Wizard tree={steps} first="intro" initialData={initialData}>
                            <Quiz />
                        </Wizard>
                    </div>
                </div>
            </div>
        </QuizContext.Provider>
    );
};

export default App;
