export const initialData = {
    "Q1": {
        answer: '', 
        nextStep: '',
    },
    "Q2": {
        answer: '', 
        nextStep: '',
    },
    "Q3": {
        answer: '', 
        nextStep: '',
    },
    "Q4": {
        answer: '', 
        nextStep: '',
    },
    "Q5": {
        answer: '', 
        nextStep: '',
    },
    "Q6": {
        answer: '', 
        nextStep: '',
    },
    "Q7": {
        answer: '', 
        nextStep: '',
    }
};
